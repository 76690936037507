@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-bone;
}

.link {
  @apply hover:bg-green cursor-pointer border-b border-black duration-300 ease-in-out;
}

.list ul {
  @apply max-w-prose list-inside list-disc space-y-1 py-5 text-base;
}

table {
  @apply mt-6 w-full pb-3;
  border-collapse: separate !important;
  border-spacing: 0;
}

thead {
  @apply mb-1.5 px-1.5 text-sm uppercase tracking-wide;
}

tr {
  @apply my-1.5 border-r duration-200 ease-in;
}

thead tr {
  @apply border-l-0 border-r-0;
}

th {
  @apply text-xxs p-2.5 font-bold uppercase tracking-widest;
}

tbody tr:first-child td:first-child {
  @apply rounded-tl-rounded;
}

tbody tr:first-child td:last-child {
  @apply rounded-tr-rounded;
}

tr td:first-child {
  @apply border-ash border-l;
}

tr td:last-child {
  @apply border-ash border-r;
}

tr:last-child td:first-child {
  @apply rounded-bl-rounded;
}

tr:last-child td:last-child {
  @apply rounded-br-rounded;
}

tr:last-child td {
  @apply border-b;
}

td {
  @apply border-ash border-t p-2.5 text-xs leading-5;
}

ol li {
  @apply before:bg-ash before:text-xxs relative pl-9 before:absolute before:left-0 before:top-0.5 before:mr-2 before:flex before:h-6 before:w-6 before:items-center before:justify-center before:rounded-full before:font-medium before:text-black before:![content:counter(section)] before:[counter-increment:section];
}

table tbody tr td code {
  font-size: 13px !important;
  @apply whitespace-pre-wrap break-all;
}

blockquote p {
  @apply md:!font-display md:!text-2xl;
}

#__next {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.docs-scrollbar-hider::-webkit-scrollbar {
  display: none;
}

.slideOverContent {
  @apply relative mt-6 flex-1 px-4 sm:px-6;
}

.baseInput {
  @apply border-ash my-1 block w-full rounded border py-2.5 text-xs duration-200 ease-in hover:border-black focus:border-black focus:ring-black;
}

.baseInputWithError {
  @apply border-red focus:ring-red focus:border-red my-1 block w-full rounded border py-2.5 text-xs;
}

.inputInFlight {
  @apply cursor-not-allowed opacity-75;
}

.btn {
  @apply focus:ring-green hover:bg-green mt-5 flex w-full rounded-full bg-black py-3 pl-5 text-sm text-white duration-200 ease-in hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.btnInFlight {
  @apply animate-pulse cursor-wait;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
